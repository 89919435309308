import { App } from './App'

import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  DirectionalLight,
  Group,
  SRGBColorSpace,
  WebGLRenderTarget,
  TextureLoader,
  LinearMipMapLinearFilter,
  Mesh,
  BufferGeometry,
  ShaderMaterial,
  PlaneGeometry,
  LinearFilter,
  Color,
  EquirectangularReflectionMapping,
  LinearToneMapping,
  PCFSoftShadowMap,
  CameraHelper,
  MeshStandardMaterial,
  MeshPhysicalMaterial,
  Texture,
} from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { Easing, Tween, Group as TweenGroup } from '@tweenjs/tween.js'

// gsap imports
import gsap from 'gsap'

// assets import
import modelImport from '../../static/model/model.glb'
import desktopBackground from '../assets/images/background.png'
import mobileBackground from '../assets/images/mobilebackground_edited_new.png'

import envMap from '../../static/studio.hdr'

// shader imports
import boxVert from './box.vert'
import boxFrag from './box.frag'

// css import
import styles from './React.module.css'

export class ReactController {
  private readonly renderer: WebGLRenderer
  private readonly scene = new Scene()
  private readonly camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  private readonly group = new Group()
  private readonly geometry?: BufferGeometry
  private readonly bgMesh: Mesh<BufferGeometry, ShaderMaterial>
  private readonly tweenGroup = new TweenGroup()
  private readonly gltfLoader = new GLTFLoader()

  private controls: OrbitControls
  private smoothValue: number
  private targetRotationX = 0
  private targetRotationY = 0
  private currentRotationX = 0
  private currentRotationY = 0
  private renderTarget: WebGLRenderTarget
  private lightStrength: number
  private lightAngle: number
  private scrollRatio: number
  private isRotated = false // for mobile
  private rgbeLoader = new RGBELoader()

  private isMobileBoolean = false
  private width = 0
  private height = 0
  private left = 0
  private top = 0
  private aspect = 1
  private vFov = 1
  private hFov = 1
  private backgroundLoader = new TextureLoader()
  private modelScale: number
  private backgroundTexture: any

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.smoothValue = 0.03
    this.updateMobileStatus()
    this.modelScale = this.isMobileBoolean ? 0.3 : 0.4
    this.scrollRatio = 0
    const canvas = this.node.querySelector(`.${styles.Canvas}`) as HTMLCanvasElement
    this.renderTarget = new WebGLRenderTarget(this.width, this.height)

    this.renderer = new WebGLRenderer({ antialias: true, canvas, alpha: true, powerPreference: 'high-performance' })
    this.renderer.setPixelRatio(
      this.isMobileBoolean ? Math.min(1, window.devicePixelRatio) : Math.min(2, window.devicePixelRatio),
    )

    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setClearColor(0xffffff, 0)
    this.renderer.outputColorSpace = SRGBColorSpace
    this.renderer.toneMapping = LinearToneMapping
    this.renderer.toneMappingExposure = 1.0
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = PCFSoftShadowMap

    // background texture based on device type
    this.backgroundTexture = this.backgroundLoader.load(
      this.isMobileBoolean ? mobileBackground : desktopBackground,
      (texture) => {
        texture.minFilter = LinearMipMapLinearFilter
        texture.magFilter = LinearFilter
        texture.generateMipmaps = true
      },
    )
    // camera
    this.camera.position.z = 5
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.scene.add(this.camera)

    window.addEventListener('scroll', () => {
      this.updateShaderColorOnScroll()
    })

    // lights
    this.lightStrength = 2
    this.lightAngle = 5
    const createLight = (color: any, intensity: number, position: [number, number, number], castShadow = false) => {
      const light = new DirectionalLight(color, intensity)
      light.position.set(...position)
      light.castShadow = castShadow
      return light
    }

    const lights = [
      createLight(0xffffff, this.lightStrength, [this.lightAngle, 0, this.lightAngle], true),
      createLight(0xffffff, this.lightStrength, [-this.lightAngle, -0, this.lightAngle], true),
    ]

    this.scene.add(...lights)

    // environment map
    this.rgbeLoader.load(envMap, (env) => {
      env.mapping = EquirectangularReflectionMapping
      this.scene.background = env
      this.scene.environment = env
    })

    // model
    const model = this.gltfLoader.load(modelImport, (gltf) => {
      gltf.scene.scale.set(0, 0, 0)
      gltf.scene.rotation.y = Math.PI / 10
      gltf.scene.rotation.z = Math.PI / 10

      gltf.scene.traverse((child) => {
        if (child instanceof Mesh && child.material.map) {
          child.material = new MeshStandardMaterial({
            map: child.material.map,
            envMap: this.scene.background as Texture,
            metalness: 1.0,
            roughness: 0,
          })
        }
      })

      this.group.add(gltf.scene)
      this.scene.add(this.group)

      gsap.to(gltf.scene.scale, {
        x: this.modelScale,
        y: this.modelScale,
        z: this.modelScale,
        duration: 0.8,
        ease: 'power2.out',
        delay: this.isMobileBoolean ? 0 : 0.5,
      })

      gsap.to(gltf.scene.rotation, {
        y: 0,
        z: 0,
        duration: 1.5,
        ease: 'power2.out',
        delay: this.isMobileBoolean ? 0.2 : 0.5,
      })
    })

    // background
    const bgGeometry = new PlaneGeometry(2, 2)
    const bgMaterial = new ShaderMaterial({
      vertexShader: boxVert,
      fragmentShader: boxFrag,
      depthTest: false,
      uniforms: {
        backgroundTexture: { value: this.backgroundTexture },
        color1: { value: new Color(0xf6381e) }, // red
        color2: { value: new Color(0x275f5f) }, // green
        swapColors: { value: 0.0 },
      },
    })
    this.bgMesh = new Mesh(bgGeometry, bgMaterial)
    this.scene.add(this.bgMesh)

    // controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.1
    this.controls.enableZoom = false
    this.controls.enablePan = false
    this.controls.enableRotate = false

    window.addEventListener('mousemove', (e) => this.onMouseMove(e))
    window.addEventListener('resize', () => {
      this.updateMobileStatus()
      this.resize()
    })

    window.addEventListener('scroll', () => {
      this.mobileScrollRotation(window.scrollY)
    })

    this.animate()
  }

  // mobile status update
  private updateMobileStatus() {
    this.isMobileBoolean = window.innerWidth < 768
  }

  // mobile scroll rotation
  mobileScrollRotation(scrollY: number) {
    if (!this.isMobileBoolean || this.isRotated) return
    const scrollRatio = Math.min(scrollY / window.innerHeight, 1)
    gsap.to(this.group.rotation, {
      y: (Math.PI * scrollRatio) / 2,
      ease: 'power1.out',
    })
  }

  // mobile color change
  updateShaderColorOnScroll() {
    const scrollY = window.scrollY
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight
    const scrollRatio = Math.min(scrollY / maxScroll, 1)

    const swapTriggerHeight = 0.5

    this.bgMesh.material.uniforms.swapColors.value = scrollRatio > swapTriggerHeight ? 1.0 : 0.0
  }

  // desktop mouse effect
  private onMouseMove(event: MouseEvent) {
    if (this.isMobileBoolean) return
    this.targetRotationY = (event.clientX / window.innerWidth - 0.5) * 0.8 // sideways pan
    this.targetRotationX = (event.clientY / window.innerHeight - 0.5) * 0.05 // up and down
  }

  // image Change Method
  imageChange() {
    const mql = window.matchMedia('(max-width: 768px)')
    this.isMobileBoolean = mql.matches
  }

  // scroll
  scroll() {
    const scroll = this.app.scrollY - this.top
    this.scrollRatio = scroll / this.height
  }

  async load() {
    this.resize()
  }

  private colorChange() {}

  // animation
  private animate() {
    requestAnimationFrame(() => this.animate())
    this.scroll()
    if (!this.isMobileBoolean) {
      this.currentRotationX += (this.targetRotationX - this.currentRotationX) * this.smoothValue
      this.currentRotationY += (this.targetRotationY - this.currentRotationY) * this.smoothValue
      this.group.rotation.x = this.currentRotationX
      this.group.rotation.y = this.currentRotationY
    }
    this.renderer.render(this.scene, this.camera)
  }

  // resize
  resize() {
    const { left, top, width, height } = this.node.getBoundingClientRect()

    this.left = left
    this.top = top + this.app.scrollY
    this.width = width
    this.height = height
    this.aspect = width / height

    this.camera.aspect = this.aspect
    this.camera.updateProjectionMatrix()

    this.vFov = (this.camera.position.z * this.camera.getFilmHeight()) / this.camera.getFocalLength()
    this.hFov = this.vFov * this.camera.aspect

    this.renderTarget.setSize(width, height)
    this.renderer.setSize(width, height)
  }

  // dispose
  dispose() {
    this.group.remove()
    this.renderTarget.dispose()
    this.scene.children.forEach((child) => this.scene.remove(child))
    this.bgMesh.material.dispose()
    this.geometry?.dispose()
    this.renderer.dispose()
  }
}
